.table-responsive {
  overflow: auto !important;
}

.clickable {
  cursor: pointer !important;
}

.paragraph-link {
  cursor: pointer !important;
  font-weight: bold;
}

.paragraph-link:hover, .paragraph-link:active {
  text-decoration: underline;
}

.danger-text {
  color: red;
}

button.link {
  background: transparent;
  border: none;
}

button.link.footer-link {
  color: #ba54f5;
  text-transform: uppercase;
}

button.link.footer-link:hover, 
button.link.footer-link:focus {
  color: #c221a9;
}

/* Detail pages */

.detail-card {
  border: 1px solid rgb(255,255,255,0.5);
}

/* AdminNavbar */

.navbar-logo {
  height: 40px;
  width: auto;
}

.navbar-balance {
  margin-left: 20px;
  text-transform: none;
}

/* OfferCard */

.card-stats .order-info {
    text-align: left; }
    .card-stats .order-info .card-title {
      margin-top: 0; }
    .card-stats .order-info .card-category {
      margin-bottom: 0;
      color: #9A9A9A; }

.card-stats .info-icon.icon-disabled {
  background: #aaaaaa;
  background-image: -webkit-linear-gradient(to bottom left, #aaaaaa, #cccccc, #aaaaaa);
  background-image: -o-linear-gradient(to bottom left, #aaaaaa, #cccccc, #aaaaaa);
  background-image: -moz-linear-gradient(to bottom left, #aaaaaa, #cccccc, #aaaaaa);
  background-image: linear-gradient(to bottom left, #aaaaaa, #cccccc, #aaaaaa);
  background-size: 210% 210%;
  background-position: top right; }

.card-stats.premium {
  border: 2px solid #c221a9;
}

/* Pagination */

.pagination .page-items {
  display: flex;
}

@media (max-width: 576px) {
  .pagination {
    display: block;
  }
  .pagination .page-items li a {
    padding: 0;
    min-width: 25px !important;
  }
}

/* Spacing */
.mt-minus-3 {
  margin-top: -16px;
}


/* Ad Blocks */

.overlay {
  /* Height & width depends on how you want to reveal the overlay (see JS below) */   
  height: 100vh;
  width: 100vw;
  position: fixed; /* Stay in place */
  z-index: 1100; /* Sit on top */
  left: 0;
  top: 0;
  background-color: rgb(0,0,0); /* Black fallback color */
  background-color: rgba(0,0,0, 0.9); /* Black w/opacity */
  overflow-x: hidden; /* Disable horizontal scroll */
  transition: 0.5s; /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
}

.overlay-content {
  position: relative;
  top: 25%; /* 25% from the top */
  width: 100%; /* 100% width */
  text-align: center; /* Centered text/links */
  margin-top: 30px; /* 30px top margin to avoid conflict with the close button on smaller screens */
}

/* Sidebar */

.sidebar[data="blue"]:before {
  border-bottom-color: transparent;
}

/* Profile page */

.card-user .avatar {
  object-fit: contain;
}

/* Products page */

.product-preview {
  width: 50px;
  max-height: 50px;
}

/* Modals */
.modal .form-control {
  color: #000;
}

/* Auth pages */

.login-page .card-login.card-white .card-header .card-title,
.card-register.card-white .card-header .card-title {
  margin-left: 10px;
  margin-top: 10px;
  text-transform: none;
}

.card-register .card-img {
  top: -9%;
}

.info-area .description .info-title {
  margin-bottom: 0;
}

.light-async-select .select__control,
.light-async-select .select__menu-list,
.light-async-select .select__option {
  background-color: white !important;
  color: rgb(82, 95, 127) !important;
}
.light-async-select .select__menu-list{
  background-color: white !important;
  color: rgb(82, 95, 127) !important;
}
.light-async-select .select__input, .light-async-select .select__single-value{
  color: rgb(82, 95, 127) !important;
}
